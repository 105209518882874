import React, { FunctionComponent, useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styled, { css } from 'styled-components';
import { ActionButton } from 'components/Buttons/ActionButton';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ISection {
  name: React.ReactNode;
  isButton?: boolean;
  noBorder?: boolean;
  withArrow?: boolean;
  isOpen?: boolean;
  openSectionOnClick?: boolean;
  buttonLabel?: string;
  children?: React.ReactNode;
}

export const Section: FunctionComponent<ISection> = ({
  name,
  isButton,
  children,
  noBorder = false,
  withArrow = false,
  openSectionOnClick = false,
  isOpen,
  buttonLabel,
}) => {
  const [isOpened, open] = useState(isOpen);

  return (
    <SectionWrapper noBorder={noBorder}>
      <NameWrapper noBorder={noBorder} buttonLabel={!!buttonLabel}>
        <div onClick={openSectionOnClick ? () => open(!isOpened) : () => {}}>
          {name}
        </div>
        {!isButton && (
          <ActionButton
            onClick={withArrow ? () => {} : () => open(!isOpened)}
            isSmall
          >
            {withArrow ? (
              <FontAwesomeIcon icon={faArrowRight as IconProp} />
            ) : (
              <>
                {buttonLabel} {isOpened ? `—` : `+`}
              </>
            )}
          </ActionButton>
        )}
      </NameWrapper>
      <ContentWrapper isOpened={isOpened}>{children}</ContentWrapper>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div<{ noBorder: boolean }>`
  ${({ noBorder, theme: { Colors, Consts } }) => css`
    width: 100%;
    border-bottom: ${
      noBorder ? `0` : `2px solid ${Colors.sectionNameWrapperBottomBorderColor}`
    }};
    @media (max-width: ${Consts.tabletBreakPoint}) {
      border-bottom: ${
        noBorder
          ? `0`
          : `1px solid ${Colors.sectionNameWrapperBottomBorderColor}`
      }};
  `}
`;

const NameWrapper = styled.div<{ noBorder: boolean; buttonLabel: boolean }>`
  ${({
    noBorder = false,
    buttonLabel = false,
    theme: { Colors, Consts },
  }) => css`
    position: relative;
    top: 2px;
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: ${buttonLabel ? 'flex-start' : 'flex-end'};
    justify-content: space-between;
    flex-direction: ${buttonLabel ? 'column' : 'row'};
    padding: 10px 0;
    border-bottom: ${
      noBorder ? `0` : `2px solid ${Colors.sectionNameWrapperBottomBorderColor}`
    }};

    @media (max-width: ${Consts.tabletBreakPoint}) {
      padding: 13px 0 10px;
      top: 1px;
      border-bottom: ${
        noBorder
          ? `0`
          : `1px solid ${Colors.sectionNameWrapperBottomBorderColor}`
      };
    }
  
    > div {
      > h1,
      > h2,
      > h3,
      > h4,
      > p {
        margin: ${buttonLabel ? '0 0 20px' : '0'};
        text-transform: uppercase;
        line-height: 36px;
    
        @media (max-width: ${Consts.tabletBreakPoint}) {
          line-height: 16px;
        }
      }
    }

    > button {
      background-color: ${Colors.sectionActionButtonBackgroundColor};
      color: ${Colors.sectionActionButtonColor};
      margin: 0;
    }
  `}
`;

const ContentWrapper = styled.div<{ isOpened: boolean }>`
  ${({ isOpened }) => css`
    margin-top: 10px;
    display: ${isOpened ? 'block' : 'none'};
  `}
`;
