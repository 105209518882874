import styled, { css } from 'styled-components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faLinkedin,
  faSpotify,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import socialURLs from 'assets/socialURLs';
import Twitter from '../../public/images/twitter.svg';

export const Socials = ({
  background = 'white',
  color = 'black',
  style,
  isBig,
}: ISocials) => {
  return (
    <SocialsWrapper style={style}>
      <A href={socialURLs.facebook}>
        <Social background={background} color={color} isBig={isBig}>
          <FontAwesomeIcon icon={faFacebookF as IconProp} style={{ color }} />
        </Social>
      </A>
      <A href={socialURLs.instagram}>
        <Social background={background} color={color} isBig={isBig}>
          <FontAwesomeIcon icon={faInstagram as IconProp} />
        </Social>
      </A>
      <A href={socialURLs.twitter}>
        <Social background={background} color={color} isBig={isBig}>
          <Twitter style={{ scale: '130%' }} />
        </Social>
      </A>
      <A href={socialURLs.tiktok}>
        <Social background={background} color={color} isBig={isBig}>
          <FontAwesomeIcon icon={faTiktok as IconProp} />
        </Social>
      </A>
      <A href={socialURLs.youtube}>
        <Social background={background} color={color} isBig={isBig}>
          <FontAwesomeIcon icon={faYoutube as IconProp} />
        </Social>
      </A>
      <A href={socialURLs.linkedin}>
        <Social background={background} color={color} isBig={isBig}>
          <FontAwesomeIcon icon={faLinkedin as IconProp} />
        </Social>
      </A>
      <A href={socialURLs.spotify}>
        <Social background={background} color={color} isBig={isBig}>
          <FontAwesomeIcon icon={faSpotify as IconProp} />
        </Social>
      </A>
    </SocialsWrapper>
  );
};

Socials.defaultProps = {
  background: 'white',
  color: 'black',
};

interface ISocials {
  background?: string;
  color?: string;
  style?: any;
  isBig?: boolean;
}

const SocialsWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    display: flex;
    align-items: center;
    @media (min-width: ${Consts.tabletBreakPoint}) {
      flex-wrap: wrap;
    }
  `}
`;

const A = styled.a`
  margin-right: 5px;
`;

export const Social = styled.div<ISocials>`
  ${({ background, color, isBig, theme: { Consts } }) => css`
    height: ${isBig ? '36px' : '26px'};
    width: ${isBig ? '36px' : '26px'};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: ${background} !important;
    svg {
      width: ${isBig ? '24px' : '16px'};
      height: ${isBig ? '24px' : '16px'};
      color: ${color} !important;
    }
    @media (min-width: ${Consts.tabletBreakPoint}) {
      height: 36px;
      width: 36px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  `}
`;
