import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { ActionButton } from 'components/Buttons/ActionButton';

interface ISection {
  name: React.ReactNode;
  isButton?: boolean;
  noBorder?: boolean;
  openedSection: string;
  buttonLabels?: string[];
  children?: React.ReactNode;
  openSection: any;
}

export const YearsSection: FunctionComponent<ISection> = ({
  name,
  isButton,
  children,
  noBorder = false,
  openedSection,
  openSection,
  buttonLabels,
}) => {
  return (
    <SectionWrapper noBorder={noBorder}>
      <NameWrapper noBorder={noBorder} buttonLabel={false}>
        <div>{name}</div>
        {!isButton && (
          <YearsWrapper>
            {buttonLabels.map((label) => (
              <ActionButton
                key={label}
                onClick={() =>
                  openedSection === label
                    ? openSection(null)
                    : openSection(label)
                }
                isSmall
              >
                {label} {openedSection === label ? `—` : `+`}
              </ActionButton>
            ))}
          </YearsWrapper>
        )}
      </NameWrapper>
      <ContentWrapper isOpened={openedSection !== null}>
        {children}
      </ContentWrapper>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div<{ noBorder: boolean }>`
  ${({ noBorder, theme: { Colors, Consts } }) => css`
    width: 100%;
    border-bottom: ${
      noBorder ? `0` : `2px solid ${Colors.sectionNameWrapperBottomBorderColor}`
    }};
    @media (max-width: ${Consts.tabletBreakPoint}) {
      border-bottom: ${
        noBorder
          ? `0`
          : `1px solid ${Colors.sectionNameWrapperBottomBorderColor}`
      }};
  `}
`;

const YearsWrapper = styled.div`
  ${({ theme: { Colors, Consts } }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;

    button {
      color: black !important;
    }
  `}
`;

const NameWrapper = styled.div<{ noBorder: boolean; buttonLabel: boolean }>`
  ${({
    noBorder = false,
    buttonLabel = false,
    theme: { Colors, Consts },
  }) => css`
    position: relative;
    top: 2px;
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px 0;
    border-bottom: ${
      noBorder ? `0` : `2px solid ${Colors.sectionNameWrapperBottomBorderColor}`
    }};

    @media (max-width: ${Consts.tabletBreakPoint}) {
      padding: 13px 0 10px;
      top: 1px;
      border-bottom: ${
        noBorder
          ? `0`
          : `1px solid ${Colors.sectionNameWrapperBottomBorderColor}`
      };
    }
  
    > div {
      > h1,
      > h2,
      > h3,
      > h4,
      > p {
        margin: ${buttonLabel ? '0 0 20px' : '0'};
        text-transform: uppercase;
        line-height: 36px;
    
        @media (max-width: ${Consts.tabletBreakPoint}) {
          line-height: 16px;
        }
      }
    }

    > button {
      background-color: ${Colors.sectionActionButtonBackgroundColor};
      color: ${Colors.sectionActionButtonColor};
      margin: 0;
    }
  `}
`;

const ContentWrapper = styled.div<{ isOpened: boolean }>`
  ${({ isOpened }) => css`
    margin-top: 10px;
    display: ${isOpened ? 'block' : 'none'};
  `}
`;
