import styled, { css } from 'styled-components';

interface ISpacer {
  height: number;
}

export const Spacer = styled.div<ISpacer>`
  ${({ height }) => css`
    width: 100%;
    height: ${height}px;
    flex-shrink: 0;
  `}
`;
