import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { twMerge } from 'tailwind-merge';
import Link from 'next/link';
import { useOutsideClick } from '../../hooks/useOutsideClick';

const languages = [
  { name: 'English', value: 'en' },
  { name: 'Italian', value: 'it' },
  { name: 'Spanish', value: 'es' },
  { name: 'Portuguese', value: 'pt' },
  { name: 'German', value: 'de' },
  { name: 'French', value: 'fr' },
  { name: 'Danish', value: 'dk' },
];

const Language = () => {
  const [isLanguageOpened, openLanguage] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(languages[0]);
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => openLanguage(false));
  const router = useRouter();

  useEffect(() => {
    switch (router.pathname) {
      case '/2025':
        setActiveLanguage(languages[0]);
        break;
      case '/2025/it':
        setActiveLanguage(languages[1]);
        break;
      case '/2025/es':
        setActiveLanguage(languages[2]);
        break;
      case '/2025/pt':
        setActiveLanguage(languages[3]);
        break;
      case '/2025/de':
        setActiveLanguage(languages[4]);
        break;
      case '/2025/fr':
        setActiveLanguage(languages[5]);
        break;
      case '/2025/dk':
        setActiveLanguage(languages[5]);
        break;
      default:
        setActiveLanguage(languages[0]);
    }
  }, [router]);

  return (
    <div
      ref={ref}
      className="relative top-[-2px] mr-[10px] sm:mr-[30px] cursor-pointer"
    >
      <button
        className="text-[#2e5b66] text-[24px] sm:text-[16px] flex items-center gap-[10px]"
        onClick={() => openLanguage(!isLanguageOpened)}
      >
        <FontAwesomeIcon icon={faGlobe as IconProp} />
        <span className="hidden sm:block">{activeLanguage.name}</span>
      </button>
      {isLanguageOpened && (
        <div className="absolute flex flex-col gap-[5px] shadow-md top-[calc(100%+15px)] w-[150px] left-auto sm:left-0 right-[-20px] sm:right-auto bg-white rounded-[10px] p-[10px]">
          {languages.map((language) => (
            <Link
              href={
                language.value === languages[0].value
                  ? '/2025'
                  : `/2025/${language.value}`
              }
              key={language.value}
              className={twMerge(
                'text-[#2e5b66] text-[16px] p-[7px_15px] rounded-[6px] transition-all',
                language.value === activeLanguage.value && 'bg-[#AFE0D74D]',
              )}
            >
              {language.name}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Language;
